import { render, staticRenderFns } from "./approval.vue?vue&type=template&id=aba23bca&scoped=true&"
import script from "./approval.vue?vue&type=script&lang=js&"
export * from "./approval.vue?vue&type=script&lang=js&"
import style0 from "./approval.vue?vue&type=style&index=0&id=aba23bca&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aba23bca",
  null
  
)

export default component.exports